<template>
  <div>

    <div class="breadCurmb mt-3">

      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>

      <span>/</span>

      <label>{{ $t("Sales") }}</label>

      <span>/</span>

      <label style="cursor:pointer" @click="goBack">
          {{ $t('Subscribes') }}
      </label>

      <span>/</span>

      <label>{{$t("Edit") }}</label>

    </div>

    <v-tabs class="mt-2" v-model="activeTab" background-color="primary" dark>
      <v-tab @click="changeComponent('EditSubscription')">تعديل  الإشتراك</v-tab>
      <v-tab @click="changeComponent('EditCustomer')">    بيانات العميل</v-tab>
      <v-tab @click="changeComponent('CustomerAddress')">      عناوين العميل</v-tab>
      <v-tab @click="changeComponent('DeliveryDetails')">  التوصيل الخاص بالإشتراك</v-tab>
    </v-tabs>

    <component :is="componentName" />
  </div>
</template>

<script>
import EditSubscription from './edit.vue'
import CustomerAddress from '/src/views/pages/customers/address/create.vue'
import EditCustomer from '/src/views/pages/customers/edit.vue'
import DeliveryDetails from './deliver_details.vue'

export default {
  components: {
    EditSubscription,
    CustomerAddress,
    DeliveryDetails,
    EditCustomer
  },
  data() {
    return {
      activeTab: null,
      componentName: 'EditSubscription'
    };
  },
  methods: {
    changeComponent(component) {
      this.componentName = component;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  created() {
    this.activeTab = 'EditSubscription';
  }

};
</script>

<style>
/* Add your custom styles here */
</style>
